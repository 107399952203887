import { CrowdPad, CrowdPadItemFilter } from '@appTypes/CrowdPadTypes'
import { RockpoolItemFilter } from '@appTypes/RockpoolTypes'
import { useRouter } from 'next/router'
import { SupportedNetworks } from '../config'
import { TokenTypeEnum } from '../graphql/variables/SharedVariable'
import { MarketplaceItem, MarketplaceItemFilter } from '../types/MarketplaceTypes'
import { useAccount } from './AccountHooks'
import { useLocalStorage } from './LocalStorageHooks'

export const useRouteParams = () => {
  const router = useRouter()

  const setItem = (key: string, value: string) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, [key]: value }
    })
  }

  const getItem = (key: string) => {
    return router.query[key]
  }

  const removeItem = (key: string) => {
    router.push({
      pathname: router.pathname,
      query: { [key]: undefined }
    })
  }

  const clear = () => {
    router.push({
      pathname: router.pathname,
      query: {}
    })
  }

  return { setItem, getItem, removeItem, clear }
}

export const useMemoryRoute = () => {
  const { getItem } = useLocalStorage()
  const { account } = useAccount()
  const network = getItem('network')
  const currentNetwork = network || SupportedNetworks.ethereum

  const path = (key: string, param?: string) => {
    let route = key
    switch (key) {
      case '/':
        route = `/${param}/${currentNetwork}`
        break
      case `/profile/${account}`:
        route = `/profile/${currentNetwork}/${account}`
        break
      case '/marketplace':
        route = `/marketplace/${currentNetwork}`
        break
      case '/marketplace/create':
        route = `/marketplace/${currentNetwork}/create`
        break
      case '/crowdPad':
        route = `/crowdPad/${currentNetwork}`
        break
      case '/crowdPad/create':
        route = `/crowdPad/${currentNetwork}/create`
        break
      case '/rockpool':
        route = `/rockpool/${currentNetwork}`
        break
      case '/rockpool/create':
        route = `/rockpool/${currentNetwork}/create`
        break
      default:
        route = key
        break
    }

    return route
  }
  return { path }
}
export const useRouteNetwork = () => {
  const { getItem } = useRouteParams()
  return Number(getItem('chainId')) || SupportedNetworks.ethereum
}

export const useRouteSort = (param: string) => {
  const { getItem } = useRouteParams()
  return getItem(param) || 'timestamp-desc'
}

export const useRouteMarketplaceSaleType = () => {
  const { getItem } = useRouteParams()
  return (getItem('saleType') as MarketplaceItemFilter) || MarketplaceItemFilter.ALL
}

export const useRouteCrowdPadSaleType = () => {
  const { getItem } = useRouteParams()
  return (getItem('saleType') as CrowdPadItemFilter) || CrowdPadItemFilter.LIVE
}

export const useRouteRockpoolSaleType = () => {
  const { getItem } = useRouteParams()
  return (getItem('saleType') as RockpoolItemFilter) || RockpoolItemFilter.live
}

export const useRouteTokenType = () => {
  const { getItem } = useRouteParams()
  return (getItem('tokenType') as TokenTypeEnum) || TokenTypeEnum.collection
}

/**
  @deprecated
  */
export const useMarketplaceRoute = (erc20: MarketplaceItem) => {
  return `/marketplace/${erc20.chainId}/detail/${erc20.id}`
}

export const useCrowdpadRoute = (crowdPad: CrowdPad) => {
  const positionHash = crowdPad.id.indexOf('#')
  const id = crowdPad.id.slice(positionHash + 1, crowdPad.id.length)
  return `/crowdpad/${crowdPad.chainId}/detail/${id}`
}
