import { chainConfig, NetworksId, NetworksName } from '@config'
import { networkFilterVar } from '@graphql/variables/SharedVariable'
import { useLocalStorage } from '@hooks/LocalStorageHooks'
import { useRouteNetwork, useRouteParams } from '@hooks/RouteHooks'
import { Tooltip } from 'antd'
import { useEffect } from 'react'
import styled from 'styled-components'
import SelectFilter from '../../design-system/SelectFilter'

import Icon from '../Icon'

interface NetworkFilterParams {
  disabledNetwork?: NetworksId[]
}

export default function NetworkFilter({ disabledNetwork }: NetworkFilterParams) {
  const { setItem } = useRouteParams()
  const handleLocalStorage = useLocalStorage()
  const setNetwork = (network: NetworksId) => {
    setItem('chainId', String(network))
    handleLocalStorage.setItem('network', String(network))
  }

  const networks = [
    {
      id: NetworksId.ethereum,
      name: chainConfig(NetworksId.ethereum).fantasyName,
      image: NetworksName.ethereum,
      action() {
        setNetwork(NetworksId.ethereum)
      },
      active: !disabledNetwork?.includes(NetworksId.ethereum)
    },
    {
      id: NetworksId.polygon,
      name: chainConfig(NetworksId.polygon).fantasyName,
      image: NetworksName.polygon,
      action() {
        setNetwork(NetworksId.polygon)
      },
      active: !disabledNetwork?.includes(NetworksId.polygon)
    }
  ]

  const testnetNetworks = [
    {
      id: NetworksId.goerli,
      name: chainConfig(NetworksId.goerli).fantasyName,
      image: NetworksName.goerli,
      action() {
        setNetwork(NetworksId.goerli)
      },
      active: !disabledNetwork?.includes(NetworksId.goerli)
    },
    {
      id: NetworksId.mumbai,
      name: chainConfig(NetworksId.mumbai).fantasyName,
      image: NetworksName.mumbai,
      action() {
        setNetwork(NetworksId.mumbai)
      },
      active: !disabledNetwork?.includes(NetworksId.mumbai)
    }
  ]

  const routeNetwork = useRouteNetwork()

  useEffect(() => {
    handleLocalStorage.setItem('network', String(routeNetwork))
  }, [handleLocalStorage, routeNetwork])

  useEffect(() => {
    networkFilterVar([routeNetwork])
  }, [routeNetwork])

  const activeNetworks = networks.find(network => network.id === routeNetwork) ? networks : testnetNetworks

  return (
    <Container>
      <Desktop>
        {activeNetworks.map(network => (
          <Tooltip title={!network.active && `Coming Soon`} key={network.name}>
            <Button
              type='button'
              onClick={() => network.active && setNetwork(network.id)}
              className={`${!network.active && 'matte'} ${routeNetwork === network.id && 'active'}`}
            >
              <Icon name={network.image} size={24} />
              <Name>{network.name}</Name>
            </Button>
          </Tooltip>
        ))}
      </Desktop>
      <Mobile>
        <SelectFilter fullWidth data={activeNetworks} value={routeNetwork || NetworksId.ethereum} />
      </Mobile>
    </Container>
  )
}
const { Button, Container, Name, Desktop, Mobile } = {
  Container: styled.div`
    display: block;
  `,
  Button: styled.button`
    background: none;
    border: none;
    cursor: pointer;

    color: ${props => props.theme.colors.black};
    display: grid;
    grid-template-columns: 40px 1fr;
    opacity: 1;

    font-size: 14px;
    font-weight: 400;
    align-items: center;
    text-align: start;
    height: 40px;
    border-radius: 8px;

    &.matte {
      filter: grayscale(1);
    }

    &.active {
      background: ${props => props.theme.colors.gray[0]};
    }

    &:hover {
      background: ${props => props.theme.colors.gray[1]};
    }
  `,
  Name: styled.div`
    margin-left: 8px;
  `,
  Desktop: styled.div`
    display: none;

    @media (min-width: ${props => props.theme.viewport.desktop}) {
      display: grid;
      grid-template-columns: 135px 125px;
      gap: 16px;
      justify-content: end;
    }
  `,
  Mobile: styled.div`
    width: 100%;

    @media (min-width: ${props => props.theme.viewport.desktop}) {
      display: none;
    }
  `
}
