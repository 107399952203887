import { Metadata } from './UtilTypes'
import { Erc721Item } from './WalletTypes'

interface Collection {
  id: string
  name: string
  symbol: string
}

interface Target {
  id: string
  tokenId: string
  tokenURI: string
  collection: Collection
  metadata: Metadata
  boxItemCount?: number
}

export interface Buyer {
  amount: string
  buyer: string
  fractionsCount: string
  ownership: string
}

interface PaymentToken {
  id: string
  name?: string
  symbol: string
  decimals: number
}

export interface CrowdPadListItem {
  amount: string
  extension: string
  fee: string
  fractions: string
  fractionsCount: string
  id: string
  limitPrice: string
  paymentToken: PaymentToken
  reservePrice: string
  seller: string
  cutoff: number | null
  buyers: Buyer[]
  status: 'CREATED' | 'FUNDED' | 'STARTED_OR_ENDING' | 'ENDING' | 'ENDED'
  target: Target
  timestamp: string
  boxCount?: number
  erc721?: Erc721Item
  name?: string
  symbol?: string
  buyersCount?: number | null
}

export interface CrowdPadBuyer {
  amount: string
  buyer: string
  ownerAddress: string
  fractionsCount: string
  percentage: string
  avatarUrl?: string
  userName?: string
}

export interface CrowdPad {
  id: string
  name?: string
  chainId: number
  amount: string
  target: Target
  symbol?: string
  extension: string
  fee: string
  nftsCount?: number
  fractions: string | null
  fractionsCount: string | null
  limitPrice: string
  paymentToken: PaymentToken
  reservePrice: string
  seller: string
  cutoff: number | null
  buyers: CrowdPadBuyer[]
  status: CrowdPadStatus
  timestamp: string
  boxCount?: number
  buyersCount?: number | null
  erc721?: Erc721Item
}

// eslint-disable-next-line no-shadow
export enum CrowdPadStatus {
  CREATED = 'CREATED',
  FUNDED = 'FUNDED',
  STARTING_OR_ENDING = 'STARTED_OR_ENDING',
  ENDING = 'ENDING',
  ENDED = 'ENDED'
}

export enum CrowdPadItemOrderBy {
  timestamp = 'timestamp',
  name = 'name'
}

// eslint-disable-next-line no-shadow
export enum CrowdPadItemFilter {
  LIVE = 'LIVE',
  CLOSED = 'CLOSED'
}
