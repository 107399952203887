import { Collection } from '../graphql/nftfy/deprecated/box/BoxByIdQuery'
import { PaymentToken } from './MarketplaceTypes'
import { Metadata } from './UtilTypes'

export interface Erc721CollectionStats {
  itemsCount?: number
  ownersCount?: number
  floorPrice?: number
  volumeTraded?: number
}

export interface Erc721Collection {
  chainId: number
  collectionAddress: string
  slug: string
  verified: boolean
  creator?: string
  collectionName?: string
  description?: string
  website?: string
  discord?: string
  instagram?: string
  telegram?: string
  twitter?: string
  image_url?: string
  banner_url?: string
  stats?: Erc721CollectionStats
}
interface TargetRockpool {
  boxItemCount: number
  chainId: number
  collection: Collection
  id: string
  tokenId: string
  metadata: Metadata
}

interface RockpoolBuyer {
  amount: string
  buyer: string
  fractionsCount: string
  id: string
  ownership: string
}

export enum RockpoolStatus {
  CREATED = 'CREATED',
  ACQUIRED = 'ACQUIRED',
  ENDED = 'ENDED'
}

export interface Erc721Rockpool {
  boxItemCount: number
  chainId: number
  url: string
  price: string
  collection: Collection
  tokenId: string
  metadata: Metadata
}

export interface RockpoolItem {
  amount: string
  chainId: number
  buyersCount: number
  creatorFee: string
  creator: string
  description: string
  fee: string
  fractions: string | null
  fractionsCount: number
  id: string
  lastBuyers: RockpoolBuyer[]
  listed: boolean
  name: string
  paymentToken: PaymentToken
  poolProgress: string
  priceMultiplier: string
  reservePrice: string
  seller: string
  sellerFeeAmount?: string
  sellerNetAmount?: string
  status: RockpoolStatus
  symbol: string
  target: TargetRockpool
  targetPrice: string
  targetPriceNet: string
  timestamp?: string
  isErc721Available: boolean
}

export enum RockpoolItemOrderBy {
  timestamp = 'timestamp',
  name = 'name'
}

// eslint-disable-next-line no-shadow
export enum RockpoolItemFilter {
  live = 'live',
  winner = 'winner'
}
