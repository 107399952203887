import { Order } from '@appTypes/OrderBookTypes'
import { Metadata } from './UtilTypes'

export interface PaymentToken {
  id: string
  name?: string
  decimals: number
  symbol: string
}

interface Collection {
  id: string
  name: string
}

interface Target {
  id: string
  collection: Collection
  tokenId: string
  tokenURI: string
  boxItemCount?: number | null
}

export interface MarketplaceItemBid {
  id: string
  txId: string
  bidder: string
  reservePrice: string
  timestamp: number
}

export interface Fraction {
  count: string
  totalSupply: string
  exitPrice: string
}

export interface MarketplaceItem {
  address?: string
  id: string
  chainId: number
  name: string
  nftCount?: number
  target: Target
  symbol: string
  seller: string
  decimals: number
  usersCount?: number
  exitPrice: string
  released: boolean
  paymentToken: PaymentToken
  timestamp?: number
  fraction: Fraction
  vaultBalance?: string
  wrapper?: string
  type: MarketplaceItemTypeEnum
  status?: MarketplaceItemStatusEnum
  metadata?: Metadata
  bids?: MarketplaceItemBid[]
  cutoff?: number
  minimumDuration?: number
}

export interface MarketplaceItemSimple {
  amount: string
  cutoff?: number
  id: string
  extra: string
  name: string
  paymentToken: PaymentToken
  reservePrice: string
  totalSupply: string
  vaultBalance: string
  fractionsCount: string
  symbol: string
  decimals: number
  released: boolean
  seller: string
  status: MarketplaceItemStatusEnum
  target: Target
  timestamp: number
  type: MarketplaceItemTypeEnum
  usersCount: number
}

export interface OrderBookLiquidity {
  fractionsLocked: string
  averagePrice: string
  totalCost: string
}

export interface MarketplaceItemLiquidity {
  orderBook: OrderBookLiquidity | null
}
export interface ERC20Asset {
  id: string
  name: string
  symbol: string
  address: string
  decimals: number
  imageUrl: string
  locked?: boolean
}

// eslint-disable-next-line no-shadow
export enum MarketplaceItemStatusEnum {
  OFFER = 'OFFER',
  AUCTION = 'AUCTION',
  SOLD = 'SOLD',
  AUCTION_OR_SOLD = 'AUCTION_OR_SOLD',
  CREATED = 'CREATED',
  FUNDED = 'FUNDED',
  STARTED_OR_ENDING = 'STARTED_OR_ENDING',
  ENDING = 'ENDING',
  ENDED = 'ENDED'
}

// eslint-disable-next-line no-shadow
export enum MarketplaceItemTypeEnum {
  AUCTION = 'AUCTION',
  SET_PRICE = 'SET_PRICE'
}

// eslint-disable-next-line no-shadow
export enum MarketplaceItemFilter {
  ALL = 'ALL',
  START_AUCTION = 'START_AUCTION',
  LIVE_AUCTION = 'LIVE_AUCTION',
  SOLD = 'SOLD',
  BUY_NOW = 'BUY_NOW'
}

// eslint-disable-next-line no-shadow
export enum MarketplaceItemOrderBy {
  timestamp = 'timestamp',
  name = 'name'
}

export interface Erc20Liquidity {
  hasLiquidity: boolean
  priceDollar: string
  impliedValuation: number
  priceFraction: string
}

export interface MarketplaceItemParticipation {
  amount: string
  percentage: string
}

export interface UpdaterOrderModal {
  order: Order
  erc20Item: MarketplaceItem
}
