import { Select } from 'antd'
import styled from 'styled-components'
import Icon from '../shared/Icon'

const { Option } = Select

export interface SelectFilterProps {
  data: { id: string | number; name: string; image?: any; action: () => void }[]
  width?: number
  fullWidth?: boolean
  value?: any
}

export default function SelectFilter({ data, width, value: defaultValue, fullWidth }: SelectFilterProps) {
  const handleChange = (value: number) => {
    const networkItem = data.find(item => item.id === value)

    if (networkItem && networkItem.action) {
      networkItem.action()
    }
  }

  return (
    <S.Container width={width} fullWidth={!!fullWidth}>
      <Select value={defaultValue} onChange={handleChange} listHeight={300}>
        {data.map(item => {
          const { id, name, image } = item
          return (
            <Option key={id} value={id}>
              {image && <Icon name={image} size={20} />}
              {name}
            </Option>
          )
        })}
      </Select>
    </S.Container>
  )
}
const S = {
  Container: styled.div<{ width?: number; fullWidth: boolean }>`
    ${props => props.width && `width: ${props.width}px`};
    ${props => props.fullWidth && 'width: 100%;'}

    > div {
      ${props => props.width && `width: ${props.width}px`};
      ${props => props.fullWidth && 'width: 100%;'}
    }

    .ant-select {
      > div {
        height: 40px !important;
        padding: 0 16px !important;
      }

      * {
        transition: none !important;
      }
    }

    .ant-select-selector {
      background-color: ${props => props.theme.colors.white} !important;
    }

    .ant-select-selector,
    .ant-select-selection-item {
      width: 100% !important;
      height: 32px !important;
      display: flex !important;
      align-items: center !important;
    }

    .ant-select-arrow .anticon {
      color: ${props => props.theme.colors.black} !important;
    }

    .ant-select-selector {
      padding-left: 8px !important;
      padding-right: 8px !important;
      border-radius: 8px !important;
      border-color: ${props => props.theme.colors.gray[1]} !important;
      box-shadow: none !important;

      &:hover {
        border-color: ${props => props.theme.colors.gray[1]} !important;
      }
    }

    .ant-select-selection-item {
      padding-left: 0 !important;
      padding-right: 0 !important;

      font-family: ${props => props.theme.fonts.primary} !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 40px !important;

      > span {
        margin-right: 6px !important;
      }
    }

    .ant-select .ant-select-selection-item,
    .ant-select-focused .ant-select-selection-item,
    .ant-select-single .ant-select-selection-item,
    .ant-select-show-arrow .ant-select-selection-item,
    .ant-select-open .ant-select-selection-item {
      color: ${props => props.theme.colors.black} !important;
      opacity: 100% !important;
    }

    .ant-select-dropdown {
      padding: 0 !important;
      display: block !important;
    }

    .ant-select-dropdown-hidden {
      display: block !important;
    }
  `,
  Label: styled.div`
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${props => props.theme.colors.gray[3]} !important;
  `
}
